import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from ".";

type Props = {
  title: string;
  description: string;
  enabled?: boolean;
} & React.PropsWithChildren;

export const Alert = ({
  children,
  title,
  description,
  enabled = true,
}: Props) => {
  return (
    <Dialog open={enabled ? undefined : false}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className="sm:max-w-md"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
