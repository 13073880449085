import {
  CellEditorSelectorResult,
  GridApi,
  GroupCellRendererParams,
  NewValueParams,
  ValueFormatterParams,
} from "ag-grid-community";
import numeral from "numeral";
import { toString } from "../../../utils";

export const valueFormatter = (params: ValueFormatterParams): string => toString(params.value);
export const valueNumberFormatter = (params: ValueFormatterParams): string => numeral(params.value).format("0,0");
export const parseStringToDate = (date: string | undefined) => {
  if (!date) return;
  return new Date(date);
};

export const getCellEditorSelector = (
  params: any,
  values: string[] | undefined,
): CellEditorSelectorResult | undefined => {
  const editable = params.data?.editable;

  if (!editable) return undefined;
  return {
    component: "agRichSelectCellEditor",
    params: {
      values,
      allowTyping: true,
      filterList: true,
      highlightMatch: true,
      valueListMaxHeight: 220,
      valueListMaxWidth: 140,
    },
  };
};

export const getRowSourceName = (state: any) => {
  const sourceName = state.source_metadata?.source_name;
  return sourceName;
};

export const getCompleteRowData = (params?: GroupCellRendererParams | NewValueParams, gridApi?: GridApi | null) => {
  const nodes: any[] = [];
  const api = gridApi || params?.api;
  api?.forEachNode((node) => {
    nodes.push(node.data);
  });
  return nodes;
};
