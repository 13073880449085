import { useMemo } from "react";
import { withModuleDashboardPageProps } from "@androshq/core/browser-client";
import { useItems } from "../../../api/hooks/items";
import DataCard from "../../../components/cards/DataCard/DataCard.tsx";
import { getQueryParams as getOveragesQueryParams } from "../../../components/cards/DataCard/queryConfigs/overages.tsx";
import { getQueryParams as getShortagesLTQueryParams } from "../../../components/cards/DataCard/queryConfigs/shortages_lt.tsx";
import { getQueryParams as getShortagesNearestQueryParams } from "../../../components/cards/DataCard/queryConfigs/shortages_nearest.tsx";
import { getQueryParams as getDemandChangesQueryParams } from "../../../components/cards/DataCard/queryConfigs/demand_changes.tsx";
import { PageActionBar, type PageActionBarData } from "../../../components/navigation/PageActionBar";
import { PageContent } from "../../../components/navigation/PageContent";
import { CLIENT_MODULE_NAME, SectionNames } from "../../../constants";
import { useCompanies } from "../../../hooks/companies/useCompanies";
import { selectAppData } from "../../../redux/appSlice";
import { useSopSelector } from "../../../redux/reducer";
import WorkflowEventsDashboardTable from "./WorkflowEventsDashboardTable/index.tsx";

export const _DashboardPage = () => {
  const { data: companies = [] } = useCompanies();
  const { data: items = [] } = useItems();

  const { selectedCompanyIds } = useSopSelector(selectAppData);

  const pageActionBarData: PageActionBarData = {
    filters: {
      pickers: [{}],
    },
  };

  const [displayConfigShortagesLT, queryConfigsShortagesLT, inputConfigsShortagesLT] = useMemo(
    () => getShortagesLTQueryParams(selectedCompanyIds, companies),
    [companies, selectedCompanyIds],
  );

  const [displayConfigShortagesNearest, queryConfigsShortagesNearest, inputConfigsShortagesNearest] = useMemo(
    () => getShortagesNearestQueryParams(selectedCompanyIds, companies),
    [companies, selectedCompanyIds],
  );

  const [displayConfigOverages, queryConfigsOverages, inputConfigsOverages] = useMemo(
    () => getOveragesQueryParams(selectedCompanyIds, companies),
    [companies, selectedCompanyIds],
  );

  const [displayConfigDemandChanges, queryConfigsDemandChanges, inputConfigsDemandChanges] = useMemo(
    () => getDemandChangesQueryParams(selectedCompanyIds, companies),
    [companies, selectedCompanyIds],
  );

  return (
    <>
      <PageActionBar pageActionBarData={pageActionBarData} />

      <PageContent className="grid md:grid-cols-2 grid-cols-1 gap-2.5">
        <DataCard
          displayConfig={displayConfigShortagesNearest}
          queryConfigs={queryConfigsShortagesNearest}
          inputConfigs={inputConfigsShortagesNearest}
        />
        <DataCard
          displayConfig={displayConfigOverages}
          queryConfigs={queryConfigsOverages}
          inputConfigs={inputConfigsOverages}
        />
        <DataCard
          displayConfig={displayConfigShortagesLT}
          queryConfigs={queryConfigsShortagesLT}
          inputConfigs={inputConfigsShortagesLT}
        />
        <WorkflowEventsDashboardTable items={items} companies={companies} />
        <DataCard
          displayConfig={displayConfigDemandChanges}
          queryConfigs={queryConfigsDemandChanges}
          inputConfigs={inputConfigsDemandChanges}
        />
      </PageContent>
    </>
  );
};

export const DashboardPage = withModuleDashboardPageProps(_DashboardPage, {
  moduleName: CLIENT_MODULE_NAME,
  sectionName: SectionNames.Dashboard,
});
