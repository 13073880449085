import React from "react";
import { CheckIcon, ChevronDownIcon, Cross2Icon } from "@radix-ui/react-icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Separator,
} from ".";
import { cn } from "..";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

const pluralize = (count: number) => (count === 1 ? "single" : "multiple");

export const getShortenedLabel = (selected: any[]) => {
  if (!selected?.length || selected.length < 2) return "";

  const count = selected.length - 1;
  const isMultiple = pluralize(count) === "multiple";
  const others = `& ${count} ${isMultiple ? "others" : "other"}`;

  return others;
};

interface Props {
  values: string[];
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  placeholder: string;
  onValueChange: (value: string[]) => void;
}

export const MultiSelect = ({ values, options, placeholder, onValueChange }: Props) => {
  const toggleOption = (value: string) => {
    const newSelectedValues = new Set(values);
    if (newSelectedValues.has(value)) {
      newSelectedValues.delete(value);
    } else {
      newSelectedValues.add(value);
    }
    onValueChange(Array.from(newSelectedValues));
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex min-w-[260px] w-fit max-w-full grow rounded-md border min-h-9 h-auto items-center justify-between bg-inside overflow-hidden">
          {Array.from(values).length > 0 ? (
            <>
              <div className="flex flex-wrap items-center w-full overflow-x-auto">
                <div className="flex items-center gap-x-1 pl-2.5 text-sm overflow-x-auto">
                  <span className="truncate">{options.find((o) => o.value === values[0])?.label}</span>
                  <span className="whitespace-nowrap">{getShortenedLabel(values)}</span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <Cross2Icon
                  className="h-4 mx-2 cursor-pointer text-muted-foreground"
                  onClick={(event: any) => {
                    onValueChange([]);
                    event.stopPropagation();
                  }}
                />
                <Separator orientation="vertical" className="flex min-h-6 h-full" />
                <ChevronDownIcon className="h-4 mx-2 cursor-pointer text-muted-foreground" />
              </div>
            </>
          ) : (
            <div className="flex items-center justify-between w-full mx-auto">
              <span className="text-sm text-muted-foreground mx-3">{placeholder}</span>
              <ChevronDownIcon className="h-4 cursor-pointer text-muted-foreground mx-2" />
            </div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-fit max-w-[260px] p-0" align="start">
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = values.includes(option.value);
                return (
                  <CommandItem key={option.value} onSelect={() => toggleOption(option.value)}>
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")} />
                    </div>
                    {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {values.length > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem onSelect={() => onValueChange([])} className="justify-center text-center">
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
