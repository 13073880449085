import { Button } from "@androshq/shared-ui";
import { CLIENT_MODULE_NAME } from "../../../../modules/sop/src/browser-client/constants";

export default function Error() {
  const handleOnLogout = () => {
    window.location.href = "/logout";
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-2.5">
      <div className="text-lg">
        We encountered an error while rendering {CLIENT_MODULE_NAME}. Logging out to clear your cache may help.
      </div>
      <Button variant="secondary" className="my-4" onClick={handleOnLogout}>
        Logout
      </Button>
      <div className="text-lg">If this doesn’t fix the problem, use the “report bug” button on bottom right.</div>
    </div>
  );
}
