import { Button } from "@androshq/shared-ui";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <h1 className="text-7xl font-bold">404</h1>
      <p className="text-muted-foreground">Woops! The page you are looking for does not exist.</p>
      <Button onClick={() => navigate(-1)}>Go Back</Button>
    </div>
  );
}
