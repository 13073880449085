import { useCallback, useEffect, useMemo, useState } from "react";
import { ReloadIcon } from "@androshq/shared-ui";
import { GetRowIdParams, GridApi, GridReadyEvent, GroupCellRendererParams, NewValueParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { subDays } from "date-fns";
import _ from "lodash";
import queryString from "query-string";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { sopApiClient } from "../../..";
import { useLocations, useVendors } from "../../../api/hooks/states";
import { calculateRowDates } from "../../../api/standards";
import {
  ItemWithConfig,
  Location,
  ShippingMethod,
  ShippingMethodEnum,
  SourceMetadata,
  State,
  Vendor,
} from "../../../api/types";
import { PageActionBar, type PageActionBarData } from "../../../components/navigation/PageActionBar";
import Loading from "../../../components/ui/Loading";
import { useCompanies } from "../../../hooks/companies/useCompanies";
import { useItems } from "../../../hooks/items/useItems";
import { generateId } from "../../../utils";
import { CAWorkflowEventRequestData, EventTypeEnum, RowGroup } from "../../workflows/changeApprovals/types";
import { WorkflowName, WorkflowVersion } from "../../workflows/types";
import SupplyPlanningTopSection from "./SupplyPlanningTopSection";
import { defaultColDef, getColumnDefs } from "./columnDefinitions";
import { getRowData, initialRowDataPreparation, sortAllRows } from "./helpers";
import { mapCompaniesToOptions, mapStatesToOptions } from "./helpers/mappers";
import { IRequisition, SupplyPlanningTableRow } from "./types";
import { getCompleteRowData } from "./utils";

const getIsNewRow = (data: Partial<IRequisition>) => data.new;

export const NEW_REQ_SOURCE_ROW_NAME = "New Req.";

export const SupplyChainPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // States
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<SupplyPlanningTableRow[] | null>(null);
  const [isStateTransitionsLoading, setIsStateTransitionsLoading] = useState(false);

  // State and item id from params
  const { id: itemId } = useParams<{ id: string }>();

  // Optional state id selection (if none we show all locations)
  const stateId = searchParams.get("state_id");
  const selectedLocations = useMemo(() => stateId?.split(",") || [], [stateId]);

  // Global data
  const { data: items, isLoading: isLoadingItems, dataUpdatedAt: itemsUpdatedAt, refetch: refetchItems } = useItems();
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies();
  const { data: vendorsAll, isLoading: isLoadingVendors } = useVendors();
  const { data: locationsAll, isLoading: isLoadingLocations } = useLocations();

  useEffect(() => {
    if (rowData && rowData.length > 0 && !isLoadingItems) {
      // We have row data
      const currentTimestampMilis = new Date().valueOf();
      if (currentTimestampMilis - itemsUpdatedAt > 1000 * 5) {
        // Last items update was more than 5 seconds ago (to prevent infinite refresh loop proactively)
        const maxLastSync = rowData
          .filter((row) => row.sourceLastSync)
          .reduce((max, row) => (!row.sourceLastSync || max > row.sourceLastSync ? max : row.sourceLastSync), "");
        if (maxLastSync.length > 0) {
          // Non-zero length source last sync string
          const lastSyncDate = new Date(maxLastSync);
          const lastItemsUpdatedAt = new Date(itemsUpdatedAt);
          if (lastSyncDate > lastItemsUpdatedAt) {
            refetchItems();
          }
        }
      }
    }
  }, [itemsUpdatedAt, refetchItems, isLoadingItems, isStateTransitionsLoading]);
  // Data for item main revision
  const item = useMemo(() => _.find(items, { id: parseInt(itemId || "0") }), [items, itemId]);
  const company = useMemo(() => _.find(companies, { id: item?.company_id }), [companies, item]);
  const vendorsMainRevision = useMemo(() => {
    if (!item || !vendorsAll) return [];
    return vendorsAll.filter((vendor) => item.config.vendor?.[vendor.id]);
  }, [item, vendorsAll]);
  const locationsMainRevision = useMemo(() => {
    if (!item || !locationsAll) return [];
    return locationsAll.filter((location) => item.config.location?.[location.id]);
  }, [item, locationsAll]);

  const companyOptions = useMemo(() => mapCompaniesToOptions(items, company), [items, company]);

  // Choices relevant for this item

  const locationOptions = useMemo(() => {
    if (!items || !item || !locationsAll) return [];
    const itemsWithSameName: ItemWithConfig[] = items.filter((elem: ItemWithConfig) => elem.name === item.name);
    const locationsForAllSameNameObjects: State[] = [];
    itemsWithSameName.forEach((item) => {
      Object.keys(item.config.location || {}).forEach((locationId) => {
        const location = locationsAll.find((location) => location.id === +locationId);
        if (location && !locationsForAllSameNameObjects.some((l) => l.id === location.id)) {
          locationsForAllSameNameObjects.push(location);
        }
      });
    });
    return mapStatesToOptions(locationsForAllSameNameObjects) || [];
  }, [item, items, locationsAll]);

  // Suppress virtual scroll in Cypress browser context to let tests have an access to any row
  const suppressGridVirtualisation = Boolean((window as any).Cypress);

  const handleChangeLocation = async (ids: string[]) => {
    const query = queryString.stringify({ state_id: ids.at(-1) });
    navigate(ids.length ? `?${query}` : "", {
      replace: true,
    });
  };

  const pageActionBarData: PageActionBarData = {
    filters: {
      multiselects: [
        {
          placeholder: "Filter to location…",
          values: selectedLocations,
          options: locationOptions,
          onValueChange: handleChangeLocation,
        },
      ],
      comboboxes: [
        {
          value: itemId!,
          options: companyOptions,
          onChange: (value) => {
            navigate({ pathname: `/sop/items/${value}/supply-chain` });
          },
        },
      ],
      switches: [
        {
          data: [
            {
              label: "Demand Forecasting",
              href: `/sop/items/${itemId}/forecasting`,
            },
            {
              label: "Supply Planning",
              href: `/sop/items/${itemId}/supply-chain`,
            },
          ],
        },
      ],
    },
  };

  const sortRowsAndUpdateEndingBalances = (
    params: GroupCellRendererParams | NewValueParams | GridApi,
    rows: SupplyPlanningTableRow[] = [],
  ) => {
    const update: SupplyPlanningTableRow[] = [];

    rows = sortAllRows(rows);

    rows.forEach((node, nodeIndex) => {
      if (nodeIndex !== 0) {
        node.endingBalance = rows[nodeIndex - 1].endingBalance + node.quantity;
        update.push(node);
      }
    });
    if ("api" in params) {
      params.api.applyTransaction({ update });
    } else {
      params.applyTransaction({ update });
    }
  };

  const insertNewRequisition = useCallback(
    (
      params:
        | GroupCellRendererParams
        | { data: SupplyPlanningTableRow; node: { rowIndex: number }; api?: GridApi | null },
      mirror: boolean = false,
    ) => {
      const finalApi = params.api || gridApi;
      if (!finalApi) return;
      const index = params.node.rowIndex!;
      const transitionData: SupplyPlanningTableRow = params.data;

      const newRowQuantity = Math.abs((mirror ? transitionData.quantity : transitionData.requisitionQuantity) || 0);
      const sourceMetadata = { ...transitionData.source_metadata } as SourceMetadata;
      if (!mirror) {
        // If not mirroring make sure we don't links to the SO record
        delete sourceMetadata.custcol_eqi_so_number;
        delete sourceMetadata.custcol_hj_po_linenum;
        delete sourceMetadata.custcol_eqi_related_group_header_line;
      }
      const txnDate = subDays(transitionData.txnDate, 0);
      const shipmentDate = subDays(txnDate, transitionData.shipmentTimeDays || 0);
      const vendorDate = subDays(shipmentDate, transitionData.vendorTimeDays || 0);

      const endingBalance = finalApi?.getDisplayedRowAtIndex(index - 1)?.data.endingBalance + newRowQuantity;

      const newRow: SupplyPlanningTableRow = {
        id: generateId(),
        vendorId: transitionData.vendorId,
        shipmentId: transitionData.shipmentId,
        shipmentStateId: transitionData.shipmentStateId,
        editable: true,

        isMainRevision: true,

        txnDate,

        locationOriginally: txnDate,
        locationExpected: txnDate,

        shipmentOriginally: shipmentDate,
        shipmentExpected: shipmentDate,

        vendorOriginally: vendorDate,
        vendorExpected: vendorDate,

        dateRequired: vendorDate,

        quantity: newRowQuantity,
        endingBalance,
        variant: transitionData.variant,
        minBalance: transitionData.minBalance,

        vendorName: transitionData.vendor,
        shippingMethod: transitionData.method,
        locationName: transitionData.locationName,

        sourceRowName: NEW_REQ_SOURCE_ROW_NAME,

        source_metadata: sourceMetadata,

        shipmentTimeDays: transitionData.shipmentTimeDays,
        vendorTimeDays: transitionData.vendorTimeDays,
        sourceLastSync: null,
        actual: _.mapValues(transitionData.actual, () => false),

        new: true,
      };

      finalApi?.applyTransaction({
        add: [newRow],
        addIndex: index,
      });

      const newRows: SupplyPlanningTableRow[] = getCompleteRowData(undefined, finalApi);
      sortRowsAndUpdateEndingBalances(finalApi, newRows);
    },
    [],
  );

  const saveRequisition = useCallback(
    async (params: GroupCellRendererParams) => {
      if (!item || !vendorsMainRevision || !locationsMainRevision) {
        throw Error(
          "Found null/undefined/empty item, vendors, or locations while saving row. This should never happen.",
        );
      }

      const index = params.node.rowIndex;
      const data = params.data;
      const isNewRow = getIsNewRow(data);

      const vendor = vendorsMainRevision.find((vendor) => vendor.name === data.vendorName);
      const shipmentStateId = data.shipmentStateId;
      const location = locationsMainRevision.find((state) => state.name === data.locationName);

      if (!vendor || !location) {
        throw Error("Failed to find vendor or location match while saving row. This should never happen.");
      }

      if (!data.quantity) {
        console.warn("Ignoring save action due to zero or null/undefined quantity.");
        return;
      }

      const rowGroup: RowGroup = {
        company_id: item.company_id,
        item_id: item.id,
        source_row_name: data.sourceRowName,

        rows: [
          {
            quantity: data.quantity,
            shipping_method: data.shippingMethod,
            source_row_id_hash: data?.sourceRowIdHash?.toString() ?? null,
            created_row_id_hash: data?.createdRowIdHash?.toString() ?? null,
            source_metadata: data?.source_metadata,
            state_transitions: [
              {
                to_state_id: vendor.id,
                state_type: "vendor",
                at: data.vendorExpected,
                actual: data.actual.vendor,
              },
              {
                to_state_id: shipmentStateId,
                state_type: "shipment",
                at: data.shipmentExpected,
                actual: data.actual.shipment,
              },
              {
                to_state_id: location.id,
                state_type: "location",
                at: data.locationExpected,
                actual: data.actual.location,
              },
            ],
          },
        ],
      };

      // Update requisition
      if (!isNewRow) {
        // Set transitions ids
        [data.vendorId, data.shipmentId, data.id].forEach((id: number, index: number) => {
          rowGroup.rows[0].state_transitions[index]["id"] = id;
        });

        const payload: CAWorkflowEventRequestData = {
          workflow_type_name: WorkflowName.ChangeApprovalsWorkflow,
          workflow_type_version: WorkflowVersion.v1_0,
          workflow_event_type: EventTypeEnum.EDIT,
          workflow_id: data.workflowId,
          workflow_run_id: data.workflowRunId,
          data: rowGroup,
        };

        const response = await sopApiClient.workflows.executions.signal(payload);

        if (response.success) {
          const update = [];
          update.push({ ...data, approvalStatus: response?.data?.state?.approval_status, editable: false });
          params.api?.applyTransaction({ update });
        }
      }
      // Create requisition
      else {
        // In case of new requisition, cant be pulling in "source_metadata" from the row that existed before
        rowGroup.rows[0].source_metadata = {
          source_name: NEW_REQ_SOURCE_ROW_NAME,
          shipping_method: data.shippingMethod,
        };

        // Populate values if this is a mirrored line (these values are cleared when line is inserted, unless it's mirrored)
        if (data.source_metadata.custcol_eqi_so_number) {
          rowGroup.rows[0].source_metadata.custcol_eqi_so_number = data.source_metadata.custcol_eqi_so_number;
        }
        if (data.source_metadata.custcol_hj_po_linenum) {
          rowGroup.rows[0].source_metadata.custcol_hj_po_linenum = data.source_metadata.custcol_hj_po_linenum;
        }
        if (data.source_metadata.custcol_eqi_related_group_header_line) {
          rowGroup.rows[0].source_metadata.custcol_eqi_related_group_header_line =
            data.source_metadata.custcol_eqi_related_group_header_line;
        }

        const payload: CAWorkflowEventRequestData = {
          workflow_type_name: WorkflowName.ChangeApprovalsWorkflow,
          workflow_type_version: WorkflowVersion.v1_0,
          workflow_event_type: EventTypeEnum.CREATE,
          data: rowGroup,
        };

        const response = await sopApiClient.workflows.executions.signal(payload);

        if (response.success) {
          const row = response.data.state.version_history[0].data.rows[0];
          const [vendor, shipment, location] = row.state_transitions;

          params.api?.applyTransaction({
            remove: [data],
            add: [
              {
                ...data,
                id: location?.id,
                shipmentId: shipment?.id,
                vendorId: vendor?.id,

                workflowId: response.data?.workflow_id || null,
                workflowRunId: response.data?.workflow_run_id || null,
                approvalStatus: response?.data?.state?.approval_status,

                sourceLastSync: row?.source_row_id_hash,
                editable: false,
                new: false,
              },
            ],
            addIndex: index,
          });
        }
      }
    },
    [item, locationsMainRevision, vendorsMainRevision],
  );

  const editRequisition = useCallback((params: GroupCellRendererParams) => {
    const update = [{ ...params.data, original: params.data, editable: true }];
    params.api?.applyTransaction({ update });
  }, []);

  const cancelRequisition = (params: GroupCellRendererParams) => {
    const isNewRow = getIsNewRow(params.data);

    if (!isNewRow) {
      params.api?.applyTransaction({
        update: [{ ...(params.data?.original || params.data), editable: false }],
      });
    } else {
      params.api?.applyTransaction({
        remove: [params.data],
      });
    }
    const newRows: SupplyPlanningTableRow[] = getCompleteRowData(params);
    sortRowsAndUpdateEndingBalances(params, newRows);
  };

  // const handleShipmentExpectedChange = (params: NewValueParams) => {
  //   const shipmentDate = params.newValue;
  //   const locationDate = addDays(shipmentDate, params.data.shipmentTimeDays || 0);

  //   params.api?.applyTransaction({
  //     update: [{ ...params.data, txnDate: locationDate, locationExpected: locationDate }],
  //   });

  //   const newRows: SupplyPlanningTableRow[] = getCompleteRowData(params);
  //   sortRowsAndUpdateEndingBalances(params, newRows);
  // };

  const updateRowDates = (params: NewValueParams) => {
    let anchorDateField: "atLocation" | "atShipment" = "atLocation";
    let anchorDate = params.data.txnDate;
    if (params.colDef.field === "shipmentExpected") {
      anchorDateField = "atShipment";
      anchorDate = params.data.shipmentExpected;
    }

    const data = params.data;

    if (data.type === "outbound") return;

    const vendorObject = _.find(vendorsMainRevision, { name: data.vendorName });

    if (item === undefined) throw new Error("Can`t find item");
    if (vendorObject === undefined) throw new Error("Can`t find vendor");

    const isNewRow = getIsNewRow(params.data);
    const isVendorActual = data.actual.vendor;

    const dates = calculateRowDates(item, vendorObject.id, data.shippingMethod, anchorDateField, anchorDate);
    const atVendor = dates.atVendor;
    const atShipment = dates.atShipment;
    const atLocation = dates.atLocation;
    const txnDate = dates.atLocation;
    const dateRequired = dates.dateRequired;

    params.api?.applyTransaction({
      update: [
        {
          ...data,
          txnDate: txnDate,
          locationExpected: atLocation,
          shipmentExpected: atShipment,
          dateRequired: dateRequired,
          ...(!isVendorActual && {
            vendorExpected: atVendor,
          }),
          ...(isNewRow && {
            locationOriginally: atLocation,
            shipmentOriginally: atShipment,
            vendorOriginally: atVendor,
          }),
        },
      ],
    });

    const newRows: SupplyPlanningTableRow[] = getCompleteRowData(params);
    sortRowsAndUpdateEndingBalances(params, newRows);
  };

  const columnDefs = getColumnDefs({
    insert: insertNewRequisition,
    save: saveRequisition,
    cancel: cancelRequisition,
    edit: editRequisition,
    item_config: item?.config,
    // remove: deleteRequisition,
    options: {
      vendorName: _.keys(item?.config?.vendor)
        .map((vendorId) => _.find(vendorsMainRevision, { id: +vendorId })?.name)
        .filter(Boolean) as string[],
      shippingMethod: [
        ShippingMethod[ShippingMethodEnum.Ocean],
        ShippingMethod[ShippingMethodEnum.FastVessel],
        ShippingMethod[ShippingMethodEnum.Air],
      ],
      locationName: locationsMainRevision?.map((state) => state.name) || [],
    },
    onCellValueChanged: {
      shipmentExpected: updateRowDates,
      txnDate: updateRowDates,
      quantity: (params) => {
        const rows = getCompleteRowData(params);
        const newRows =
          rows?.map((row) => {
            if (row.id === params.data.id) return { ...row, quantity: params.newValue };
            return row;
          }) || [];

        sortRowsAndUpdateEndingBalances(params, newRows);
      },
      endingBalance: (params) => {
        console.log(params);
        const rows = getCompleteRowData(params);
        const newRows =
          rows?.map((row) => {
            if (row.id === params.data.id) return { ...row, endingBalance: params.newValue };
            return row;
          }) || [];

        sortRowsAndUpdateEndingBalances(params, newRows);
      },
      vendorName: updateRowDates,
      shippingMethod: updateRowDates,
    },
  });

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);
  const getRowId = useCallback((params: GetRowIdParams) => params.data.id, []);

  const fetchStateTransition = async (
    itemIds: number[],
    items: ItemWithConfig[],
    vendorsAll: Vendor[],
    locationsAll: Location[],
    itemId: number,
  ) => {
    setIsStateTransitionsLoading(true);
    const rowDataArray: SupplyPlanningTableRow[][] = [];
    try {
      await Promise.all(
        itemIds.map(async (id) => {
          const stateIdForRequest: number | undefined = stateId ? +stateId : undefined;
          const data = await sopApiClient.items.item.getStateTransitions(id, stateIdForRequest);
          if (!data.success) throw new Error(data.message);
          const itemFull = items?.find((item) => item.id === id);
          if (!itemFull) {
            throw new Error("Item not found");
          }
          const vendorsForItem: Vendor[] = vendorsAll?.filter((vendor) => itemFull?.config?.vendor?.[vendor.id]);
          const locationsIdsForItem = locationsAll
            ?.filter((location) => itemFull?.config?.location?.[location.id])
            .map((location) => location.id);

          rowDataArray.push(getRowData(itemFull, locationsIdsForItem, data.data, vendorsForItem, itemId));
          return data.data;
        }),
      );
      return rowDataArray.flat();
    } catch (error) {
      console.error("Error fetching state transitions", error);
    } finally {
      setIsStateTransitionsLoading(false);
    }
  };

  const setupRowData = async () => {
    if (!items || !item || !vendorsAll || !locationsAll || !gridApi) return;
    const itemsWithSameName: ItemWithConfig[] = items.filter(
      (elem: ItemWithConfig) =>
        elem.name === item.name && elem.source_metadata.item_name_prefix == item.source_metadata.item_name_prefix,
    );
    const response = await fetchStateTransition(
      itemsWithSameName.map((elem) => elem.id),
      items,
      vendorsAll,
      locationsAll,
      +item.id,
    );
    if (response) {
      const sortedData: SupplyPlanningTableRow[] = sortAllRows(response);
      const initialDataAfterPreparation: SupplyPlanningTableRow[] = initialRowDataPreparation(sortedData);
      setRowData(initialDataAfterPreparation);
    } else {
      setRowData(null);
    }
  };

  useEffect(() => {
    setupRowData();
  }, [item, items, isLoadingVendors, isLoadingLocations, stateId, gridApi]);

  useEffect(() => {
    if (!gridApi) return;

    if (isStateTransitionsLoading || isLoadingItems || isLoadingVendors || isLoadingLocations || isLoadingCompanies) {
      gridApi.showLoadingOverlay();
    } else if (!rowData?.length) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        columnDefs,
      });
    }
  }, [
    gridApi,
    isStateTransitionsLoading,
    rowData,
    columnDefs,
    isLoadingItems,
    isLoadingVendors,
    isLoadingLocations,
    isLoadingCompanies,
  ]);
  if (!itemId) return <Loading />;
  return (
    <>
      <PageActionBar
        className="flex items-start justify-between flex-wrap gap-3"
        pageActionBarData={pageActionBarData}
      />

      <div className="flex flex-col gap-y-2.5 h-full">
        <div className="px-2.5 pt-2.5">
          {item ? (
            <SupplyPlanningTopSection
              gridApi={gridApi}
              item={item}
              sortRowsAndUpdateEndingBalances={sortRowsAndUpdateEndingBalances}
              insertNewRequisition={insertNewRequisition}
            />
          ) : null}
        </div>
        <div data-item-supply-planning-grid className="ag-theme-quartz w-full h-full min-h-[400px]">
          <AgGridReact
            columnDefs={columnDefs}
            enableBrowserTooltips={true}
            suppressAutoSize
            suppressCellFocus
            reactiveCustomComponents
            stopEditingWhenCellsLoseFocus
            loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
            noRowsOverlayComponent={() => <div>No data</div>}
            rowClass="group"
            singleClickEdit={true} // Better UX for editing
            suppressColumnVirtualisation={suppressGridVirtualisation}
            suppressRowVirtualisation={suppressGridVirtualisation}
            {...{ defaultColDef, getRowId, onGridReady }}
          />
        </div>
      </div>
    </>
  );
};
