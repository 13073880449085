import { useEffect, useMemo, useState } from "react";
import { Button, DownloadIcon, HeightIcon, Label } from "@androshq/shared-ui";
import { GridApi, GroupCellRendererParams, NewValueParams } from "ag-grid-community";
import { subDays } from "date-fns";
import { getDefaultItemVendorConfig } from "../../../api/standards";
// import { measures } from "../../../api/cube";
import { ItemWithConfig } from "../../../api/types";
// import BarChart from "../../../components/cards/charts/BarChart";
// import MetricsCard from "../../../components/cards/metrics-card/MetricsCard";
import Tooltip from "../../../components/messages/Tooltip";
import VendorSummaryTable from "../../../components/tables/SupplyTopTable";
// import {
//   SupportedGranularityOptions,
//   buildDateRangeOutwardFromNextPeriodStart,
// } from "../../../components/tables/standards";
import { Switch } from "../../../components/ui/Switch";
import { useFetchStates } from "../../../hooks/states/useFetchStates";
import { generateId } from "../../../utils";
import { sortAllRows } from "./helpers";
import { SupplyPlanningTableRow } from "./types";

interface Props {
  item: ItemWithConfig;
  gridApi: GridApi | null;
  sortRowsAndUpdateEndingBalances: (
    params: GroupCellRendererParams | NewValueParams | GridApi,
    rows: SupplyPlanningTableRow[],
  ) => void;
  insertNewRequisition: (
    params:
      | GroupCellRendererParams
      | { data: SupplyPlanningTableRow; node: { rowIndex: number }; api?: GridApi | null },
    mirror: boolean,
  ) => void;
}

export default function SupplyPlanningTopSection({
  item,
  gridApi,
  sortRowsAndUpdateEndingBalances,
  insertNewRequisition,
}: Props) {
  const { data: vendors } = useFetchStates({ state_type: "vendor" });
  // Finishing time days overrde
  const [finishingTimeDaysOverride, setFinishingTimeDaysOverride] = useState<number | null>(null);

  const mappedData = useMemo(() => {
    if (!item || !vendors) return;

    return (
      Object.keys(item.config.vendor).map((key) => {
        return {
          vendor: item.config.vendor[key],
          item: item.config,
        };
      }) || []
    );
  }, [item, vendors]);

  const updateAndReorderRows = () => {
    if (!gridApi) return null;

    const finishingTimeDays =
      finishingTimeDaysOverride === null ? item.config.finishing_time_days || 0 : finishingTimeDaysOverride;
    const defaultItemVendorConfig = getDefaultItemVendorConfig(item);
    const rowDataToSort: any[] = [];
    gridApi.forEachNode((node) => {
      let txnDate = node.data.txnDate;
      let dateRequired = node.data.dateRequired;
      if (node.data.type == "outbound") {
        txnDate = subDays(node.data.departLocationExpected, finishingTimeDays);
        dateRequired = subDays(
          txnDate,
          defaultItemVendorConfig.vendor_time_days + defaultItemVendorConfig.shipment_time_days,
        );
      }
      rowDataToSort.push({ ...node.data, txnDate: txnDate, dateRequired: dateRequired });
    });
    const sortedRows = sortAllRows(rowDataToSort);
    gridApi.setGridOption("rowData", sortedRows);
    sortRowsAndUpdateEndingBalances(gridApi, sortedRows);
  };

  const appendNewReq = () => {
    if (!gridApi) return;
    let lastNodeIndex = 0;
    gridApi.forEachNode((node) => {
      if (node.rowIndex === null) return;
      lastNodeIndex = node.rowIndex;
    });
    if (lastNodeIndex === undefined) return;
    const lastRowData = gridApi.getDisplayedRowAtIndex(lastNodeIndex)?.data;

    const defaultItemVendorConfig = getDefaultItemVendorConfig(item);

    const locationId = Object.keys(item.config.location)[0];
    const locationConfig = item.config.location[locationId];
    const vendorIdFound = Object.entries(item.config.vendor).reduce((foundId, [id, config]) => {
      if (config.name === defaultItemVendorConfig.name) {
        return parseInt(id);
      }
      return foundId;
    }, 0);
    let vendorId = String(vendorIdFound);
    if (vendorIdFound === 0) {
      vendorId = Object.keys(item.config.vendor)[0];
    }

    const vendorTimeDays = defaultItemVendorConfig.vendor_time_days;
    const shipmentTimeDays = defaultItemVendorConfig.shipment_time_days;

    const txnDate = subDays(lastRowData.txnDate, 0);
    const shipmentDate = subDays(txnDate, shipmentTimeDays);
    const vendorDate = subDays(shipmentDate, vendorTimeDays);

    const qty = Math.max(item.config.item_lot_size, defaultItemVendorConfig.minimum_order_quantity);

    const fakeRow: SupplyPlanningTableRow = {
      id: generateId(),
      type: "inbound",
      isMainRevision: true,
      variant: item.variant || "",
      vendorId: parseInt(vendorId),
      shipmentId: 12,
      shipmentStateId: 12,
      editable: true,

      txnDate: txnDate,

      locationOriginally: txnDate,
      locationExpected: txnDate,

      shipmentOriginally: shipmentDate,
      shipmentExpected: shipmentDate,

      vendorOriginally: vendorDate,
      vendorExpected: vendorDate,

      dateRequired: vendorDate,

      quantity: qty,
      requisitionQuantity: qty,
      endingBalance: lastRowData.endingBalance,
      minBalance: locationConfig.item_inventory_quantity_min,

      vendorName: defaultItemVendorConfig.state_name,
      vendor: defaultItemVendorConfig.state_name,

      shippingMethod: "Ocean",
      locationName: locationConfig.state_name,

      sourceRowName: "New Req.",

      source_metadata: {
        source_name: "New Req.",
        shipping_method: "Ocean",
      },

      shipmentTimeDays: shipmentTimeDays,
      vendorTimeDays: vendorTimeDays,
      sourceLastSync: null,
      actual: {
        vendor: false,
        shipment: false,
        location: false,
      },
      method: "Ocean",
      new: true,
    };
    insertNewRequisition(
      {
        data: fakeRow,
        node: {
          rowIndex: lastNodeIndex + 1,
        },
        api: gridApi,
      },
      false,
    );
  };

  const exportTable = () => {
    gridApi?.exportDataAsExcel();
  };

  useEffect(() => {
    // Default this to null when changing item
    setFinishingTimeDaysOverride(null);
  }, [item]);

  useEffect(() => {
    updateAndReorderRows();
  }, [finishingTimeDaysOverride]);

  return (
    <div className="flex gap-2.5 justify-between flex-wrap h-36">
      <div className="flex-1 flex flex-col">
        <div className="flex-1 inline-flex items-end justify-between mx-6 mb-2">
          <Tooltip tooltip="Download .xlsx">
            <Button onClick={exportTable} className="flex items-center gap-1.5 text-xs" variant="outline" size="sm">
              <DownloadIcon />
              Export Table
            </Button>
          </Tooltip>
          <Tooltip tooltip="Add row at bottom (for items without existing rows)">
            <Button onClick={appendNewReq} className="flex items-center gap-1.5 text-xs" variant="outline" size="sm">
              Append Row
            </Button>
          </Tooltip>
          <div className="inline-flex space-x-4 items-center">
            <Tooltip tooltip="Disabling buffer orders demand by Date Due" className="mr-4 text-xs">
              <div className="flex items-center space-x-2 text-xs">
                <Label htmlFor="buffer-toggle" className="text-xs">
                  Include {item.config.finishing_time_days} day buffer
                </Label>
                <Switch
                  id="buffer-toggle"
                  size="sm"
                  checked={finishingTimeDaysOverride === null}
                  onCheckedChange={(checked) => {
                    const newOverride = checked ? null : 0;
                    setFinishingTimeDaysOverride(newOverride);
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip tooltip="Reorder based on Txn Date">
              <Button
                onClick={updateAndReorderRows}
                className="flex items-center gap-1.5 text-xs"
                variant="outline"
                size="sm"
              >
                <HeightIcon />
                Reorder Lines
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="borderborder-gray-200 shadow p-2 rounded-xl flex-[3]">
          <VendorSummaryTable rows={mappedData} isLoading={vendors ? false : true} />
        </div>
      </div>
      {/* <MetricsCard
        title="Current Forecast vs Historical Performance"
        granularity={SupportedGranularityOptions.month}
        granularityIsFixed={true}
        dateRange={buildDateRangeOutwardFromNextPeriodStart(6, 5, SupportedGranularityOptions.month)}
        dateRangeIsFixed={true}
        measures={[measures.actual.total, measures.forecast.leadTime.total, measures.forecast.mostRecent.total]}
        itemList={[item]}
        contentComponent={BarChart}
      /> */}
    </div>
  );
}
