import { useState } from "react";
import { Button, Calendar, Popover, PopoverContent, PopoverTrigger, cn } from "@androshq/shared-ui";
import { TimeDimensionGranularity } from "@cubejs-client/core";
import { format } from "date-fns";
import { StandardDateFormat, formatDateRangeAsIntervalString } from "../../tables/standards";

export type DateRange = { from: Date; to: Date };

type DateRangePopoverProps = {
  dateRange: DateRange | undefined;
  granularity: TimeDimensionGranularity | undefined;
  onChange: (value: DateRange | undefined) => void;
  fixed: boolean;
};

const renderDateRangeLabel = (dateRange: DateRange, granularity: TimeDimensionGranularity = "week") => {
  const interval = formatDateRangeAsIntervalString([dateRange?.from, dateRange?.to], granularity);
  const from = format(dateRange.from, StandardDateFormat.FULL);
  const to = format(dateRange.to, StandardDateFormat.FULL);
  return `${interval} (${from} - ${to})`;
};

export default function CardDateRangePicker({
  dateRange,
  granularity,
  onChange,
  fixed = false,
}: DateRangePopoverProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [dateRangeInternal, setDateRange] = useState<DateRange | undefined>(dateRange);

  const handleApply = () => {
    onChange(dateRangeInternal);
    setOpen(false);
  };
  if (fixed && dateRange) {
    return (
      <div className="w-fit h-6 px-2 py-1 bg-zinc-100 rounded-md shadow-none border-none text-xs transition">
        {renderDateRangeLabel(dateRange, granularity)}
      </div>
    );
  }
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={cn("flex items-center gap-x-1 h-6 px-2 py-0 bg-zinc-100 rounded-md transition leading-none", {
          "opacity-60": open,
        })}
      >
        {dateRangeInternal ? (
          <span className="text-xs">{renderDateRangeLabel(dateRangeInternal, granularity)}</span>
        ) : (
          <span>Pick a date</span>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 rounded-lg" align="end">
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={dateRangeInternal?.from}
          selected={dateRangeInternal}
          numberOfMonths={3}
          /* @ts-expect-error SharedUI import */
          onSelect={setDateRange}
        />
        <div className="flex justify-end p-4 pt-0">
          <Button onClick={handleApply}>Apply</Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
