import { Query } from "@cubejs-client/core";
import { ColDef, ColGroupDef } from "ag-grid-community";
import ItemNameCell from "../../../tables/cells/ItemNameCell";
import { BuiltQueryConfig, DisplayConfig, DynamicInputs, InputConfigs } from "../DataCard";
import { format } from "date-fns";

const customColumnDefs: (ColGroupDef<any> | ColDef<any, any>)[] = [
  {
    field: "demand_changes-company_name",
    headerName: "Company",
    flex: 1,
    cellClass: "cursor-pointer",
    width: 120,
    maxWidth: 120,
  },
  {
    field: "item_name",
    headerName: "Item",
    flex: 1,
    cellClass: "cursor-pointer",
    width: 120,
    maxWidth: 120,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <ItemNameCell
              item_name={
                Object.keys(params.data).find((key) => key.endsWith("item_name"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name"))!]
                  : ""
              }
              item_name_prefix={
                Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))!]
                  : ""
              }
              item_variant={
                Object.keys(params.data).find((key) => key.endsWith("item_variant"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_variant"))!]
                  : ""
              }
            />
          );
        },
      };
    },
  },
  {
    field: "demand_changes-quantity_current",
    headerName: "Current Qty",
    flex: 1,
    cellClass: "cursor-pointer",
    sortable: true,
  },
  {
    field: "demand_changes-quantity_prior",
    headerName: "Prior Qty",
    flex: 1,
    cellClass: "cursor-pointer",
    sortable: true,
  },
  {
    field: "demand_changes-quantity_change_version_prior",
    headerName: "Version Prior",
    flex: 1,
    cellClass: "cursor-pointer",
    sortable: true,
    valueGetter: (params) => {
      const rawValue = params.data['demand_changes-quantity_change_version_prior'];
      const value = typeof rawValue === 'string' ? new Date(rawValue) : rawValue;
      return value;
    },
    valueFormatter: (params) => {
      if (!params.value) return '-';
      return format(params.value, 'MM/dd/yyyy');
    },
  },
  {
    field: "demand_changes-quantity_pct_change",
    headerName: "Pct Change",
    flex: 1,
    cellClass: "cursor-pointer",
    sortable: true,
    valueGetter: (params) => {
      const rawValue = params.data['demand_changes-quantity_pct_change'];
      const value = typeof rawValue === 'string' ? parseFloat(rawValue) : rawValue;
      return value;
    },
    valueFormatter: (params) => {
      console.log(params.value, params.data['demand_changes-quantity_pct_change'],  params.data['demand_changes-quantity_current'], params.data['demand_changes-quantity_prior'])
      if (isNaN(params.value)) return '-';
      const maxPct = 500;
      const value = Math.round(params.value*100);
      if (value > maxPct) return `+${maxPct}%+`;
      if (value < -maxPct) return `-${maxPct}%+`;
      return `${value > 0 ? '+' : ''}${value}%`;
    },
  },
  {
    field: "demand_changes-quantity_wtd_change", 
    headerName: "MT WT Change",
    flex: 1,
    cellClass: "cursor-pointer",
    sort: "desc",
    sortable: true,
    sortIndex: 1,
    valueGetter: (params) => {
      const rawValue = params.data['demand_changes-quantity_wtd_change'];
      const value = typeof rawValue === 'string' ? parseFloat(rawValue) : rawValue;
      return Math.abs(value);
    },
    valueFormatter: (params) => {
      if (isNaN(params.value)) return '-';
      const rawValue = params.data['demand_changes-quantity_wtd_change'];
      const value = typeof rawValue === 'string' ? parseFloat(rawValue) : rawValue;
      const sign = value < 0 ? '-' : '+';
      return `${sign}${Math.round(params.value / 1000)} MT`;
    },
  }
];

export const getQueryParams = (
  selectedCompanyIds: string[],
  companies: any[],
): [DisplayConfig, BuiltQueryConfig[], InputConfigs] => {
  const dynamicInputs: DynamicInputs = {
    timeDimensions: [],
    filters: [
      {
        inputDataKey: "company_ids",
        member: "demand_changes.company_id",
        operator: "equals",
      },
    ],
  };

  const baseQuery: Query = {
    dimensions: [
      "demand_changes.company_name",
      "demand_changes.item_name",
      "demand_changes.item_name_prefix",
      "demand_changes.item_variant",
      "demand_changes.item_id",
      "demand_changes.quantity_current",
      "demand_changes.quantity_prior",
      "demand_changes.quantity_pct_change",
      "demand_changes.quantity_wtd_change",
      "demand_changes.quantity_change_version_prior",
    ],
    order: [["demand_changes.date_required", "asc"]],
  };
  const displayConfig: DisplayConfig = {
    cardTitle: "Demand Changes (Next 36 Weeks, Current vs Prior Version)",
    outputType: "table",
    noRowsMessage: selectedCompanyIds.length ? "No demand changes found" : "Select a customer to see results",
    columnDefsCustom: customColumnDefs,
  };

  const queryConfigs: BuiltQueryConfig[] = [
    {
      baseQuery: baseQuery,
      dynamicInputs: dynamicInputs,
    },
  ];

  const inputConfigs: InputConfigs = {
    company_ids: {
      disabled: true,
      inputDataType: "multiSelect",
      selectedIds: selectedCompanyIds,
      options: companies,
    },
  };
  return [displayConfig, queryConfigs, inputConfigs];
};
