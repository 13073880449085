import _ from "lodash";
import {
  ApprovalStatus,
  Approver,
  CAWorkflowEvent,
  CAWorkflowExecution,
  Version,
  WorkflowTypeName,
} from "../pages/workflows/changeApprovals/types";

export const ReasonVendorSelectionCT = {
  0: "",
  1: "Price",
  2: "Over Capacity",
  3: "Under Utilized",
  4: "Quality Issue",
  5: "Contractual Agreement",
  6: "Environmental Closure",
  7: "Labor Issue",
  8: "Financial Considerations",
  9: "Supplier Equipment Issue",
  101: "Lead Time",
  102: "Management Issue",
  103: "Supplier in Development",
  104: "Early Production Ramp-Up",
  105: "Vendor Relationship",
  106: "Geo-Political Issue",
  107: "Multi Source Strategy",
};

export const ReasonVendorSelectionPCL = {
  0: "",
  1: "Price",
  2: "Over Capacity",
  3: "Under Utilized",
  4: "Quality Issue",
  5: "Contractual Agreement",
  6: "Environmental Closure",
  7: "Labor Issue",
  8: "Financial Considerations",
  9: "Supplier Equipment Issue",
  101: "Lead Time",
  102: "Management Issue",
  103: "Supplier in Development",
  104: "Early Production Ramp-Up",
  105: "Vendor Relationship",
  106: "Geo-Political Issue",
  107: "Multi Source Strategy",
};

export enum ShippingMethodEnum {
  Ocean = "Ocean",
  FastVessel = "Fast Vessel",
  Air = "Air",
}

export const ShippingMethod = {
  [ShippingMethodEnum.Ocean]: "Ocean",
  [ShippingMethodEnum.FastVessel]: "Fast Vessel",
  [ShippingMethodEnum.Air]: "Air",
};

// Unclear why we need this... -AC

// Note for AC:
// Because we needed a func to simplify getting shipping method value by its label displayed on grids in the past.
// Now as far as we have values (used for API calls) same as labels, we can kill this function.
export const getShippingMethodEnumByLabel = (method: (typeof ShippingMethod)[keyof typeof ShippingMethod]) => {
  return _.findKey(ShippingMethod, (v) => v === method) as `${ShippingMethodEnum}`;
};

export enum StateType {
  vendor = "vendor",
  shipment = "shipment",
  location = "location",
}

export enum IdentityType {
  USER = "USER",
  SYSTEM = "SYSTEM",
}

export const APPROVAL_STATUS_ID_TEXT_MAPPING: Record<number, string> = {
  1: "Unapproved",
  2: "Sample Do Not Use",
  3: "Approved",
  4: "Conditional-Closure",
  5: "In Development",
  6: "Conditionally Approved",
  12: "Conditional-Action",
  13: "Rejected-Redevelop",
  14: "Rejected-No Action",
  15: "Obsolete Revision",
  16: "Cancelled",
  17: "To Be Reviewed",
  18: "Approved - Idle",
  19: "In Development - Idle",
  20: "Conditional-Closure - Idle",
  21: "Conditionally Approved - Idle",
  22: "Conditional-Action - Idle",
  999: "Inactive",
};

export type User = {
  id: string;
  given_name: string;
  family_name: string;
  email: string;
};

export type State = {
  id: number;
  name: string;
  state_type: `${StateType}`;
};

export type Vendor = State & {
  state_type: `${StateType.vendor}`;
};

export type Location = State & {
  state_type: `${StateType.location}`;
};

export type Company = {
  id: number;
  name: string;
  parent_name: string;
};

export interface CompanyConfigShippingMethods {
  shipment_time_days_fastvessel?: number;
  shipment_time_days_air?: number;
}

export interface ItemCompanyConfig extends CompanyConfigShippingMethods {
  name: string;
}

export type ItemVendorConfig = {
  max_weekly_item_capacity: number;
  minimum_order_quantity: number;
  shipment_time_days: number;
  vendor_preferred_for_item: 0 | 1;
  item_vendor_priority: number; // force ranked list on status then lead time
  vendor_status_id: number;
  vendor_time_days: number;
  state_name: string;
  name: string;
};
StateType;

export type ItemLocationConfig = {
  balance_snapshot_date: number; // epoch seconds
  balance_snapshot_quantity: number;
  item_inventory_quantity_min: number;
  name: string;
  state_name: string;
};

export type ItemConfig = {
  finishing_time_days: number;
  item_lot_size: number;
  company: ItemCompanyConfig;
  vendor: {
    [vendor_id: string]: ItemVendorConfig;
  };
  location: {
    [location_id: string]: ItemLocationConfig;
  };
};

export type Identity = {
  id: string;
  type: IdentityType;
};
export interface IdentityUser extends Identity {
  type: IdentityType.USER;
  given_name: string;
  family_name: string;
}
export interface IdentitySystem extends Identity {
  type: IdentityType.SYSTEM;
}

export enum ItemType {
  InventoryItem = "Inventory Item",
  Assembly = "Assembly",
  // Incomplete list but not impactful
}
export interface ItemSourceMetadata {
  item_type: ItemType;
  item_name_prefix?: string;
}
export interface Item {
  id: number;
  name: string;
  description?: string;
  variant?: string;
  company_id: number;
  weight: number;
  price?: number;
  source_metadata: ItemSourceMetadata;
}

export interface ItemWithConfig extends Item {
  config: ItemConfig;
}

export type SourceMetadata = {
  source_type?: string;
  source_id?: string;
  source_line_id?: string;
  source_ns_account?: string;
  source_name: string;
  shipping_method: `${ShippingMethodEnum}`;
  container_no?: string;
  reason_vendor_selection_ct?: 0 | 2 | 7 | 1 | 3 | 4 | 5 | 6 | 8 | 9 | 101 | 102 | 103 | 104 | 105 | 106 | 107; // control tower's reason (PCL may have different set)
  reason_vendor_selection_pcl?: 0 | 2 | 7 | 1 | 3 | 4 | 5 | 6 | 8 | 9 | 101 | 102 | 103 | 104 | 105 | 106 | 107;
  custcol_eqi_so_number?: string;
  custcol_hj_po_linenum?: string;
  custcol_eqi_related_group_header_line?: string;
  date_to_pcl?: string;
  overriddden_quantity?: number;
  overriddden_quantity_recommended?: number;
};

export const sourceMetadataMirrorFields = [
  "custcol_eqi_so_number",
  "custcol_hj_po_linenum",
  "custcol_eqi_related_group_header_line",
];

export type StateTransitionFull = {
  id: number;
  prior_transition_id: number | null;
  item_id: number;
  from_state_id: number | null;
  to_state_id: number | null;
  at: string;
  originally_at: string;
  actual: boolean;
  quantity: number;
  quantity_recommended: number | null;
  source_metadata: SourceMetadata | null;
  source_last_sync: string | null;
  version: string;
  is_active: boolean;
  state_type: "location" | "shipment" | "vendor";
  is_fixed: boolean;
  created_at: string;
  source_row_id_hash: string | number;
  created_row_id_hash: string | number;
  workflow_run_id_lock: string;
  workflow_execution?: Pick<CAWorkflowExecution, "workflow_id" | "workflow_run_id" | "search_attributes">;

  // State fields joined
  name: string;
  state_id: number;
};

export type TransitionFull = {
  id: number;
  prior_transition_id: number | null;
  item_id: number;
  from_state_id: number | null;
  to_state_id: number | null;
  at: string;
  originally_at: string;
  actual: boolean;
  quantity: number;
  quantity_recommended: number | null;
  source_metadata: SourceMetadata | null;
  source_last_sync: string | null;
  version: string;
  is_active: boolean;
  state_type: "location" | "shipment" | "vendor";
  is_fixed: boolean;
  created_at: string;
  source_row_id_hash: string | number;
  created_row_id_hash: string | number;
  workflow_run_id_lock: string;
  integration_id: number | null;
  line_parent_name: string | null;
  line_parent_type: string | null;
  line_parent_id: string | null;
  line_parent_id_source: string | null;
  line_id: string | null;
  line_id_source: string | null;
};

export enum WorkflowStatus {
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

export interface WorkflowState {
  approvers: Approver[];
  event_history: CAWorkflowEvent[];
  approval_status: ApprovalStatus;
  version_history: Version[];
}
export interface WorkflowExecutionSearchAttributes {}

export type WorkflowExecution = {
  workflow_type_version: string;
  workflow_id: string;
  workflow_run_id: string;
  end_time: Date | null;
  search_attributes: WorkflowExecutionSearchAttributes;
  start_time: string;
  workflow_type_name: WorkflowTypeName;
  status: `${WorkflowStatus}`;
  state: WorkflowState;
};

export interface WorkflowEventRequestData {
  workflow_type_name: string;
  workflow_type_version: string;
  workflow_event_type: string;
  workflow_id?: string;
  workflow_run_id?: string;
  memo?: string | null;
  data?: unknown;
}

export interface WorkflowEventData extends WorkflowEventRequestData {
  triggered_by: IdentityUser | IdentitySystem;
}
export const DAYS_CARGO_READY_TO_SHIP = 7;
