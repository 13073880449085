import * as React from "react";

import { cn } from "../lib/utils";
import { IconProps } from "@radix-ui/react-icons/dist/types";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: (props: IconProps) => JSX.Element;
  wrapperClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ wrapperClassName, className, type, icon: Icon, ...props }, ref) => {
    return (
      <div className={cn("relative", wrapperClassName)}>
        {Icon && (
          <Icon className="text-muted-foreground w-4 h-4 pointer-events-none absolute top-1/2 left-3 transform -translate-y-1/2" />
        )}
        <input
          className={cn(
            "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            className,
            Icon && "pl-9"
          )}
          type={type}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
